import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import { PageHeader } from '../components/PageHeader'
import PostList from '../components/Posts/PostList'
import { decodeEntities } from '../utils/helpers'
import { NewsletterSignUp } from '../components/NewsletterSignUp'

export default class IndexPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const {
      wordpressPost: page = [],
      allWordpressPost,
      categoryFilter,
      categories,
      wordpressWpSettings,
      siteSettings,
    } = data
    const { name, yoast, acf } = page
      ? page
      : { title: null, yoast: {}, acf: {} }
    const { title: siteTitle } = wordpressWpSettings
    const { edges: posts } = allWordpressPost
    const { newsletterText } = siteSettings.options

    return (
      <>
        <SEO
          title={`${
            yoast.metaTitle
              ? yoast.metaTitle
              : `Blog | ${decodeEntities(siteTitle)}`
          }`}
          desc={yoast.metaDescription}
          yoast={yoast}
          location={location}
        />
        <PageHeader
          headerTitle={page.acf.title ? page.acf.title : page.acf.name}
          headerSubTitle={page.description}
          location={location}
        />
        <PostList
          posts={posts}
          title="Latest posts"
          pageContext={pageContext}
          categoryFilter={categoryFilter}
          siteMetadata={wordpressWpSettings}
          pathPrefix={wordpressWpSettings.blogSlug}
        />
        <NewsletterSignUp content={newsletterText} />
      </>
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      siteUrl
    }
    wordpressPost: wordpressCategory(slug: { eq: "uncategorised" }) {
      name
      slug
      path
      description
      acf {
        featureImage {
          source_url
        }
        title
      }
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
    }
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          path
          id
        }
      }
    }
    allWordpressPost(
      filter: { slug: { ne: "gatsby-demo" } }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
        }
      }
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        newsletterText
      }
    }
  }
`
